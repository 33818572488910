





















































































import {
  defineComponent,
  ref,
  onMounted,
  onUpdated,
  useFetch,
  computed,
  useContext,
  onBeforeUnmount,
  onActivated,
  onDeactivated,
  watch,
  PropType,
  toRefs,
  nextTick
} from "@nuxtjs/composition-api";
import { SfIcon, SfLoader, SfImage, SfCircleIcon, SfLink, } from "@storefront-ui/vue";
import { merge } from 'lodash-es';
import Swal from "sweetalert2";
import apichoice from "~/modules/fortytwo/dynamicyield/helper/dynamicyield";
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import AddToWishlist from '~/components/AddToWishlist.vue';
import type { Product } from '~/modules/catalog/product/types';
import { useAddToCart } from "~/helpers/cart/addToCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from '~/modules/customer/composables/useUser';
import { useImage } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import DYPrice from "~/components/FortyTwo/homepage/DYRecommendation/DYPrice.vue";
import { useUiNotification } from '~/composables/useUiNotification';
import DYProductWithEmpty from "~/components/FortyTwo/homepage/DYRecommendation/DYProductWithEmpty.vue";
import { Flicking } from "@egjs/vue-flicking";
import { Arrow } from "@egjs/flicking-plugins";
import ProductLabel from '~/modules/fortytwo/product/components/ProductLabel.vue';

export default defineComponent({
  name: "DYProduct",
  components: {
    SfImage,
    SfCircleIcon,
    AddToWishlist,
    SvgImage,
    SfIcon,
    SfLoader,
    DYPrice,
    SfLink,
    DYProductWithEmpty,
    Flicking,
    ProductLabel,
  },
  props: {
      data: {
      type: Array,
      required: true,
      default: []
      },
      title: {
      type: String,
      required: true,
      },
      decisionID: {
      type: String,
      },
      variationID: {
      type: Number,
      },
  },
 
  setup(props) {
    const { apidycall, loadingdy, dyresultdata } = apichoice();
    const dydata:any = computed(() => props.data ?? []);
    const dyDecisionID:any = computed(() => props.decisionID ?? '');
    const dyVariationID:any = computed(() => props.variationID ?? '');
    const { app } = useContext();
    const wishlistproductsku = ref([]);
    const productSku:any = ref("");
    const { getProductList, loading } = useProduct();
    const product = ref<Product | null>(null);
    const checkaddtocart = ref(true);
    const itemadding = ref([]);
    const wlitemadding = ref([]);
    const { addItemToCart, error: cartError } = useAddToCart();
    const { isAuthenticated } = useUser();
    const wishlistStore = useWishlistStore();
    const {send: sendNotification} = useUiNotification();
    const dyProductrefresh = ref(null); 
 
    const {
      load: loadWishlist,
      addItem: addItemToWishlistBase,
      isInWishlist,
      removeItem: removeItemFromWishlist,
    } = useWishlist();

    const addItemToWishlist = async (product: Product) => {
      await (isInWishlist({ product })
        ? removeItemFromWishlist({ product })
        : addItemToWishlistBase({ product }));
        getWishlistItem();
    };

    const getWishlistItem = async () => {
      const apiState = app.$vsf.$magento.config.state;
      if(apiState.getCustomerToken()){
        if (!wishlistStore.wishlist.id) {
          await loadWishlist({});
        }
        const productspath = wishlistStore.wishlist?.items_v2?.items;
        wishlistproductsku.value = [];
        for (let index = 0; index < productspath?.length; index++) {
          wishlistproductsku.value.push(productspath[index]?.product.sku);  
        }
      }
    }

    watch(
      isAuthenticated,
      (newVal) => {
        if (isAuthenticated){
          getWishlistItem()
        }
      },
    );
    
    const addItemToWishlistLogin = () => {
      sendNotification({
        id: Symbol('wishlist_updated'),
        message: app.i18n.t('Oh no, we have a problem here! Remember to log in to add this item to your wishlist.') as string,
        type: 'warning',
        icon: '',
        persist: false,
        title: 'Wishlist Notification',
      });
    };

    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: productSku.value,
        },
      },
    });

    const fetchProductBaseData = async (searchQuery = getBaseSearchQuery()) => {
      const result = await getProductList({
        ...searchQuery,
      });
      product.value = merge({}, product.value, result?.items[0] as Product ?? null);  
    };

    const { imageSizes: { cart: imageSize } } = useImage();

    onUpdated(async () =>{
      if (!wishlistStore?.wishlist?.id) {
        loadWishlist();
      }
    })

    onDeactivated(async () =>{
      await nextTick();
      dyProductrefresh.value?.destroy();
    }) 

    onActivated(async () =>{
      await nextTick();
      dyProductrefresh.value?.init();
    }) 

    onMounted(async () => { 
      await Promise.all([ getWishlistItem()]);
    });

    const currentURL = window.location.href;

    const dyEngagementcalls = async (decisionID: string ,variationsId: number, slotid:string) => {
      await apidycall(decisionID,"SLOT_CLICK","Engagements",[variationsId],slotid.toString(),currentURL,false); 
    }

    const addtothecart = async (productSkua: string, decisionID: string ,variationsId: number, slotid:string) => {
      itemadding.value.push(productSkua);
      productSku.value = productSkua;
      await fetchProductBaseData();
      await apidycall(decisionID,"SLOT_CLICK","Engagements",[variationsId],slotid.toString(), currentURL,false); 
      await addItemToCart({ product:product.value, quantity: 1, itmConfigurationSelected:'', canAddConfigItmToCart: false});

      if (product.value.__typename == 'SimpleProduct') {
        if (cartError.value?.addItem?.message) {          
          sendNotification({
            id: Symbol('product_added_to_cart'),
            message: cartError.value.addItem.message,
            persist: false,
            title: 'AddToCart Notification',
            type: 'danger',
            icon: '',
          });
        } else {
          sendNotification({
            id: Symbol('product_added_to_cart'),
            message: app.i18n.t('{product} was added to your cart.',{ product: product.value?.name },) as string,
            persist: false,
            title: 'Added To Cart',
            type: 'success',
            icon: 'check',
          });
        }

      }
      itemadding.value.pop();
    }

    const checkaddcartloading = (productSkua: string) => {
      return itemadding.value.includes(productSkua);
    }

    const addtowishlist = async (productSkua: string) => {
      wlitemadding.value.push(productSkua);
      productSku.value = productSkua;
      const apiState = app.$vsf.$magento.config.state;
      if(apiState.getCustomerToken()){
        await fetchProductBaseData();
        await addItemToWishlist(product.value);
        // checkwishlist(productSkua);
      } else{
        addItemToWishlistLogin();
      }
      wlitemadding.value.pop();
    }

    const checkwishlist = (productSkua: string) => {
      if(isAuthenticated){
        return wishlistproductsku.value.includes(productSkua)?'heart_fill':'heart';
      }else{
        return 'heart';
      }  
    }

    const checkwishlistloading = (productSkua: string) => {
      return wlitemadding.value.includes(productSkua);
    }

    return {
       dydata,
       imageSize,
       addtothecart,
       dyEngagementcalls,
       addtowishlist,
       addItemToWishlistLogin,
       isAuthenticated,
       isInWishlist,
       checkwishlist,
       checkaddcartloading,
       checkwishlistloading,
       dyDecisionID,
       dyVariationID,
      plugins: [new Arrow({ moveCount: 2 })],
      dyProductrefresh,
    };
  },
});
