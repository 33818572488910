import axios from "axios";
import { ref,useContext  } from "@nuxtjs/composition-api";
import { merge } from "lodash-es";
import { setItem } from "~/helpers/asyncLocalStorage";

export const apichoice = () => {
  const loadingdy = ref(true);
  const dyresultdata = ref();
  const error = ref("");
  const { $cookies } = useContext();
  const dyCompleteArray = ref([]);
  const dyid_servers = ref("");
  const dyid_sessions = ref("");
  const dyid_data = ref("");
 
  const devicetype = () => {
    const windowWidth = window.innerWidth;
    const breakpoints = {
      desktop: 1024,
      tablet: 768,
      mobile: 480
    };

    if (windowWidth >= breakpoints.desktop) {
      return 'desktop';
    } else if (windowWidth >= breakpoints.tablet && windowWidth < breakpoints.desktop) {
      return 'tablet';
    } else {
      return 'smartphone';
    }
  }

  dyid_data.value = $cookies.get('_dyid') ?? '';
  if(dyid_data.value){
    // $cookies.set('_dyid_server',dyid_data.value,{path:'/', maxAge:31556951});
    dyid_data.value = dyid_data.value.toString();
  }

  dyid_servers.value = $cookies.get('_dyid_server') ?? '';
  dyid_sessions.value = $cookies.get('_dyjsession') ?? '';
   if(dyid_servers.value){
    dyid_servers.value = dyid_servers.value.toString();
   }
   
  const apicontentcheck = async (dataId, contextType, apitype, variationId,slotIddata,clientdata,currentURL,isForPageview) =>{
    if(apitype === "Choose"){
      return [
      {
        user: {
            dyid: dyid_data.value,
            dyid_server: dyid_servers.value,
            active_consent_accepted: true
          },

        session: {
            dy: dyid_sessions.value
          },
        
        selector: {
          names: dataId,
        },

        context: {
          page: {
            type: contextType,
            location: currentURL,
            locale: "en_SG",
            data : variationId
          },
          device: {  
              userAgent: clientdata.data.clientuseragent, 
              type: devicetype(),
              ip: clientdata.data.clientipaddress
          },
        },
        options: { 
          isImplicitPageview: isForPageview 
        } 
      }]
    }else if(apitype === "Engagements"){
      return [
        {
          user: {
              dyid: dyid_data.value,
              dyid_server: dyid_servers.value,
              active_consent_accepted: true
            },
  
          session: {
              dy: dyid_sessions.value
            },

            context: {
              device: {
                ip: clientdata.data.clientipaddress
              }
            },

          engagements: [
              {
                type: contextType,
                decisionId: dataId,
                variations:variationId,
                slotId:slotIddata
              }
            ]
        }]
    }
  }

  const apidycall = async (dataId, contextType, apitype, variationId,slotIddata,currentURL,isForPageview) => {
    const clientdata = await axios.get('/client-header');
    setItem("clientData", clientdata.data.clientipaddress);
    const apicontent = await apicontentcheck(dataId, contextType, apitype, variationId,slotIddata,clientdata,currentURL,isForPageview);
    const apicontentdata = apicontent[0];
    const API_URL = ref();
    if(apitype == "Choose"){
       API_URL.value = "/dynamic-yield-choose";
    }else{
      API_URL.value = "/dynamic-yield-engagement";
    }
    
    try {
      const response = await axios.post(API_URL.value, apicontentdata);
      loadingdy.value = false;

      if(apitype == "Choose"){
        const dyObjectChoices = response.data.dyresponsedata.choices;
        for (let choiceIndex = 0; choiceIndex < dyObjectChoices.length; choiceIndex++) {
          const dyVariationArray = ref([]);
          const dySKUArray = ref([]);
          const dyCollectVariation = ref({});
          const dyFinalArray = ref({});
          const imageDesktopdata = ref();
          const imageTabletdata = ref();
          const imageSmartPhonedata = ref();
          
          const dyskuarray = ref([]);
          const dyObjectChoicesPath = response.data.dyresponsedata.choices[choiceIndex];
          const dyObjectChoicesDecisionId = dyObjectChoicesPath.decisionId;
          const dyObjectChoicesType = dyObjectChoicesPath.type;
          if(dyObjectChoicesType == "DECISION"){
            const dyObjectChoicesName = dyObjectChoicesPath.name;
            const dyObjectVariations = dyObjectChoicesPath.variations;
            for (let variationsIndex = 0; variationsIndex < dyObjectVariations.length; variationsIndex++) {
              const dyObjectVariationsPath = dyObjectVariations[variationsIndex];
              const dyObjectVariationsId = dyObjectVariations[variationsIndex].id;
              imageDesktopdata.value = dyObjectVariationsPath.payload?.data?.imageLarge;

              imageTabletdata.value = dyObjectVariationsPath.payload?.data?.imageMedium;
              if(!imageTabletdata.value){
                imageTabletdata.value = imageDesktopdata.value;
              }

              imageSmartPhonedata.value = dyObjectVariationsPath.payload?.data.imageSmall;
              if(!imageSmartPhonedata.value){
                imageSmartPhonedata.value = imageDesktopdata.value;
              }

              const dyimageAlt = dyObjectVariationsPath.payload?.data.imageAlt;
              const dyURL = dyObjectVariationsPath.payload?.data.URL;
              const dyorder = dyObjectVariationsPath.payload?.data.order;
              const dylabelColor = dyObjectVariationsPath.payload?.data.labelColor;
              const dylabelText = dyObjectVariationsPath.payload?.data.labelText;
              const dytitle = dyObjectVariationsPath.payload?.data.title;
              const dymessage = dyObjectVariationsPath.payload?.data.message;

              dyCollectVariation.value = merge({}, dyCollectVariation.value, 
                { 
                  variationsId : dyObjectVariationsId,
                  imageDesktop : imageDesktopdata.value, 
                  imageTablet : imageTabletdata.value, 
                  imageSmartPhone : imageSmartPhonedata.value, 
                  imageAlt : dyimageAlt,
                  URL : dyURL,
                  order : dyorder,
                  labelColor : dylabelColor,
                  labelText : dylabelText,
                  title : dytitle,
                  message : dymessage,
                });

              dyVariationArray.value.push(dyCollectVariation.value);
            }

            dyFinalArray.value = merge({},dyFinalArray.value,
              {
                data : dyVariationArray, 
                campaigntype : dyObjectChoicesName,
                decisionId : dyObjectChoicesDecisionId
              });

          }else if(dyObjectChoicesType == "RECS_DECISION"){
            const dyObjectChoicesName = dyObjectChoicesPath.name;
            const dyObjectChoicesDecisionId = dyObjectChoicesPath.decisionId;
            const dyObjectSlots = dyObjectChoicesPath.variations[0]?.payload.data.slots;
            const dyObjectTitle = dyObjectChoicesPath.variations[0]?.payload.data.custom.title;
            const dyObjectVariationsId = dyObjectChoicesPath.variations[0]?.id;
            if(dyObjectSlots){
              for (let slotsIndex = 0; slotsIndex < dyObjectSlots.length; slotsIndex++) {
                const dyObjectSlotsPath = dyObjectSlots[slotsIndex];
                const dysku = dyObjectSlotsPath.sku;
                const dyslotId = dyObjectSlotsPath.slotId;
                const dyimage = dyObjectSlotsPath.productData.image_url;
                const dyimagelink = dyObjectSlotsPath.productData.url;
                const dydisplayprice = dyObjectSlotsPath.productData.dy_display_price;
                const dyprice = dyObjectSlotsPath.productData.price;
                const dyproductid = dyObjectSlotsPath.productData.product_id;
                const dyproducturl = dyObjectSlotsPath.productData.url;
                const dyname = dyObjectSlotsPath.productData.name;
  
                dyCollectVariation.value = merge({}, dyCollectVariation.value, 
                  { 
                    image : dyimage, 
                    imagelink : dyimagelink, 
                    displayprice : dydisplayprice, 
                    price : dyprice, 
                    productid : dyproductid, 
                    name : dyname, 
                    producturl : dyproducturl, 
                    sku : dysku,
                    slotId : dyslotId
                  });
  
                  dyVariationArray.value.push(dyCollectVariation.value);
                  dySKUArray.value.push(dysku);
              }
            }
            
            dyFinalArray.value = merge({},dyFinalArray.value,
              {
                variationsId : dyObjectVariationsId,
                data : dyVariationArray, 
                campaigntype : dyObjectChoicesName, 
                title:dyObjectTitle,
                decisionId :dyObjectChoicesDecisionId,
                itemsku : dySKUArray
              });
          }
          dyCompleteArray.value.push(dyFinalArray.value);
        }
      }

      dyresultdata.value = dyCompleteArray.value;
      return response;
    } catch (err) {
      console.error('checkerror',err);
      error.value = err;
      return err;
    }
  };

  return {
    apidycall,
    loadingdy,
    dyresultdata,
    error
  };
};

export default apichoice;
